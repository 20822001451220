<template>
  <div class="process-line">
    <div class="line-items" v-for="(item, index) in lineList" :key="index">
      <el-checkbox
        class="checkbox"
        v-model="item.checked"
        @change="handleCheckedChange(item.id, $event)"
        v-if="checkShow"
      ></el-checkbox>
      <p>{{ item.title }}</p>
      <div class="tips-list">
        <div class="tips" v-for="(t, i) in item.tag" :key="i">
          {{ t }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "processLine",
  props: {
    checkShow: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
    },
  },
  data() {
    return {
      selectLength: [],
      lineList: [],
    };
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.lineList = JSON.parse(JSON.stringify(newVal));
        this.lineList.forEach((item) => {
          if (!Array.isArray(item.tag)) {
            item.tag = JSON.parse(item.tag);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 选择流程
    handleCheckedChange(id, value) {
      if (value) {
        this.selectLength.push(id);
      } else {
        this.selectLength.splice(this.selectLength.indexOf(id), 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.process-line {
  width: 597px;
  height: 100%;
  background: #0e2138;
  margin-right: 12px;
  padding: 46px 59px 0 49px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #3e90e5;
  }
  .line-items {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      background: #fff;
      border: 3px solid #3e90e5;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 92px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 30px;
      left: 101px;
      width: 1px;
      height: 100%;
      border-right: 1px dashed #3e90e5;
    }
    &:last-of-type::after {
      display: none;
    }
    p {
      width: 105px;
      padding: 9px;
      min-height: 38px;
      font-size: 18px;
      color: #3e90e5;
    }
    .tips-list {
      width: 77%;
      padding: 0 0 48px 20px;
      display: flex;
      flex-wrap: wrap;
      .tips {
        min-width: 94px;
        height: 38px;
        background: rgba(62, 144, 229, 0.11);
        border: 1px solid rgba(62, 144, 229, 0.4);
        border-radius: 3px;
        padding: 0 4px;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        color: #3e90e5;
        margin: 0 25px 14px 0;
        // &:nth-of-type(3n) {
        //   margin-right: 0;
        // }
      }
    }
    /deep/.checkbox {
      padding: 10px 18px 0 0;
      .el-checkbox__inner {
        border: 1px solid #385576;
        background-color: #0e2138;
      }
      .is-checked .el-checkbox__inner {
        background-color: #409eff;
      }
    }
  }
}
</style>
